var menu = function () {

    this.bindEvents = function () {
        var $nestable = $('#nestable');
        $nestable.nestable();
        $nestable.nestable('collapseAll');

        $('.dd-handle a').on('mousedown', function(e) {
            e.stopPropagation();
            app.href();
        });

        $nestable.on('change', function() {
            var url = $nestable.attr("data-url");
            var menu = $nestable.nestable('serialize');

            app.block(1);
            $.post(url, {menu: menu, operation: 'reorder'})
                .success(function (data) {
                    if(data.response) {
                        app.success("", "Ordinamento riuscito!");
                    } else {
                        app.warning("", data.message);
                        app.reload();
                    }
                    app.block(0);
                })
                .error(function () {
                    console.error("Errore Ajax!");
                    app.error("", "Errore Ajax!");
                    app.block(0);
                    app.reload();
                });

        });
    };

    this.bindEvents();

};