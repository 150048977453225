var user = function(dataTable) {

	this.bindEvents = function() {
		$('[data-interaction=enable]').unbind('click').bind('click', function() {
			var id = $(this).closest('tr').attr('data-id');
			var disable = $(this).attr('data-disable');
			var url = "user/" + id + "/enable" + (disable ? "?enable=0" : "?enable=1");

			app.block(1);
			$.post(url)
				.success(function(data) {
					if(data.response) {
						app.success('Utente ' + (disable ? 'disabilitato' : 'abilitato'));
						dataTable.table.draw('page');
					} else {
						app.warning('', data.message);
					}
					app.block(0);
				})
				.error(function() {
					app.block(0);
					app.error('', 'Ajax error!');
				});
		});
	};

	this.bindEvents();

};
