var formatter = {

    bool: function(table, td, cell_data, row_data, row_index, col_index) {
        return cell_data ? '<i style="color: green;" class="fa fa-circle"></i>' : '<i style="color: red;" class="fa fa-circle"></i>';
    },

    user_enabled: function(table, td, cell_data, row_data, row_index, col_index) {
        var ret = "";

        if(cell_data) {
            ret = '<button data-interaction="enable" data-disable="1" class="btn btn-xs btn-success"><i class="fa fa-check"></i></button>'
        } else {
            ret = '<button data-interaction="enable" class="btn btn-xs btn-danger"><i class="fa fa-times"></i></button>'
        }

        return ret;
    },

    /*cambio_stato_rilevazione: function(table, td, cell_data, row_data, row_index, col_index) {
        if(cell_data) {
            return '<i data-status="1" data-id="'+ row_data[0] +'" data-interaction="cambio_stato" style="color: green;" class="fa fa-check"></i>';
        } else {
            return '<i data-status="0" data-id="'+ row_data[0] +'" data-interaction="cambio_stato" style="color: red;" class="fa fa-times"></i>';
        }

    },*/

    /**
     * Visualizzazione immagine in base all'url contenuto nella cella
     *
     * @param table
     * @param td
     * @param cell_data
     * @param row_data
     * @param row_index
     * @param col_index
     */
    picture: function(table, td, cell_data, row_data, row_index, col_index) {
        if (cell_data) {
            var img_url = cell_data;

            if (img_url.indexOf('http') !== 0)
                img_url = img_url;          // TODO derelativizzare url...

            return "<img class='fnone' src='" + img_url + "' title='" + cell_data + "'>";
        } else {
            return cell_data;
        }
    },
    /**
     * Visualizzazione immagine in base all'url contenuto nella cella
     *
     * @param table
     * @param td
     * @param cell_data
     * @param row_data
     * @param row_index
     * @param col_index
     */
    pista_level: function(table, td, cell_data, row_data, row_index, col_index) {
        var img_url = 'images/img_pista/';

        switch (cell_data) {
            case 0:
                return '<i style="color:#00A9FF;" class="fa fa-circle"></i>';
            case 1:
                return '<i style="color:#FF1400 ;" class="fa fa-circle"></i>';
            case 2:
                return '<i style="color:#000000 ;" class="fa fa-circle"></i>';
            default:
                return '';
        }


    },

    tipo_pista_impianto: function (table, td, cell_data, row_data, row_index, col_index) {
        if(cell_data == 1) {
            return "Pista";
        } else {
            return "Impianto"
        }
    },

    bool_stato_impianto: function(table, td, cell_data, row_data, row_index, col_index) {
        if(cell_data) {
            return '<i style="color: green;" class="fa fa-check"></i>';
        } else {
            return '<i style="color: red;" class="fa fa-times"></i>';
        }
    }

};